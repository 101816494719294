.hr-line::after{
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 1px;
  content: " ";
  background: linear-gradient(90deg,#fff 0,#d6d6d6 50%,#fff);
}

.drawer-mask{
  opacity: .3;
    height: 100% !important;
    transition: opacity .3s cubic-bezier(.78,.14,.15,.86);
    background: #000;
    opacity: 0;
    height: 0 !important;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity .3s cubic-bezier(.78,.14,.15,.86),height 0s ease .3s;
  
}

.category-slider button.prev {
  background-color: #10b981;
}
.swiper-button-next:after, .swiper-button-prev:after{
  background-color: #10b981;
  color:#fff !important;
  border-radius: 3px !important;
  font-size: 100% !important;
  width: 30px;
  height: 30px;
  text-align: center;
  display: flex;
  justify-content:center;
  align-items: center;
}
.rs-drawer-backdrop{
  z-index: 0;
}

#popup-root{
  position: fixed;
  z-index: 2000;
}


.swiper-pagination-bullet-active{
  background-color: rgb(16 185 129) !important;
}

.swiper-button-next{
  right: 0px !important;
}
.swiper-button-prev{
  left: 0px !important;
}

@media (max-width:480px){
  .rs-drawer-left.rs-drawer-xs, .rs-drawer-right.rs-drawer-xs {
    width: 100% !important;
  }
}

.black-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;
  z-index: 1500;
}