@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll-input::-webkit-inner-spin-button,
.no-scroll-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-scroll-input {
  scrollbar-width: none;
}

.no-scroll-input::-webkit-scrollbar {
  display: none;
}

/* Styles for the default checkbox */
input[type="checkbox"] {
  /* Hide the default checkbox appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding:10px;
}

/* Styles for the checked state */
input[type="checkbox"]:checked {
  background-color: #059669; /* Green color */
  border-color: #059669;
  height:15px;
}

/* Styles for the checkmark inside the checkbox */
input[type="checkbox"]::after {
  content: "\2713"; /* Checkmark symbol */
  display: block;
  font-size: 12px;
  color: white; /* Color of the checkmark */
  text-align: center;
  line-height: 10px;
  margin: 0;
}


/* Styles for the default radio button */
input[type="radio"] {
  /* Hide the default radio button appearance */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

/* Styles for the checked state */
input[type="radio"]:checked {
  background-color: #059669; /* Green color */
  border-color: #059669;
}

/* Styles for the radio dot inside the button */
input[type="radio"]::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: white; /* Color of the radio dot */
  border-radius: 50%;
  margin: 2px auto;
}

/* Styles for the radio dot in the checked state */
input[type="radio"]:checked::after {
  background-color: white; /* Color of the radio dot when checked */
}


